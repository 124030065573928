<ng-container *ngIf="!this.modalOpen">
  <sbx-button
    *ngIf="buttonType === 'standalone-button'"
    [title]="
      stakeholderInvited ? 'Resend Invitation' : 'Invite to Fidelity Private Shares'
    "
    icon="envelope"
    (click)="populateForm()"
  >
  </sbx-button>
  <a class="btn btn-link" (click)="populateForm()" *ngIf="buttonType === 'dropdown'">
    <i class="fa fa-envelope"></i>
    <span *ngIf="!stakeholderInvited">Invite to Fidelity Private Shares</span>
    <span *ngIf="stakeholderInvited">Resend Invitation</span>
  </a>
  <sbx-popover
    *ngIf="buttonType === 'icon'"
    [sbxPopoverContent]="
      stakeholderInvited ? 'Resend Invitation' : 'Invite to Fidelity Private Shares'
    "
    sbxPopoverPlacement="top"
    sbxPopoverContainer="body"
  >
    <a (click)="populateForm()" *ngIf="buttonType === 'icon'">
      <i class="fa fa-envelope"></i>
    </a>
  </sbx-popover>
</ng-container>
<ng-template #content>
  <sbx-modal-header> Send Fidelity Private Shares Invitation </sbx-modal-header>

  <sbx-modal-content>
    <ng-container *ngIf="stakeholderName && stakeholderEmail && !relationshipDeclined">
      <div>
        <p *ngIf="!stakeholderEntity">
          Invite <b>{{ stakeholderName }}</b> (<b>{{ stakeholderEmail }}</b
          >) to join Fidelity Private Shares?
        </p>
        <p *ngIf="stakeholderEntity">
          Invite
          <span *ngIf="!stakeholderFund">
            <b> {{ stakeholderEntity }} </b>
          </span>
          <span *ngIf="stakeholderFund">
            <b> {{ stakeholderFund }}</b
            >, a fund of <b>{{ stakeholderEntity }} </b>
          </span>
          to join Fidelity Private Shares? An invitation will be sent to
          <b>{{ stakeholderName }}</b> (<b>{{ stakeholderEmail }}</b
          >)
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="relationshipDeclined">
      <b>{{ stakeholderName }}</b> (<b>{{ stakeholderEmail }}</b
      >) has declined the previous invitation. Use the Edit button below to confirm the
      email for this invitation.
    </ng-container>
    <ng-container *ngIf="!stakeholderEmail && stakeholderName">
      <p>
        No email address has been specified for <b>{{ stakeholderName }}</b
        >. Use the Edit button below to add an email for this user.
      </p>
    </ng-container>
    <ng-container *ngIf="!stakeholderName && stakeholderEmail">
      <p>
        No name has been specified for <b>{{ stakeholderEmail }}</b
        >. Use the Edit button below to add a name for this user.
      </p>
    </ng-container>
    <ng-container *ngIf="!stakeholderName && !stakeholderEmail">
      <p>
        No data has been specified for the contact user. Use the Edit button below to
        details for this user.
      </p>
    </ng-container>
  </sbx-modal-content>

  <sbx-modal-footer>
    <sbx-button
      title="Send Invitation"
      theme="primary"
      [disabled]="
        !stakeholderEmail || relationshipDeclined || !canInvite || !stakeholderName
      "
      (click)="sendInvitation()"
    >
    </sbx-button>
    <sbx-edit-stakeholder
      stakeholderId="{{ stakeholderId }}"
      [stakeholderInvited]="false"
      buttonType="modal"
      [canInvite]="canInvite"
      [canEdit]="canEdit"
      [statusCallback]="statusCallback"
      (modalOpened)="closeModal()"
    >
    </sbx-edit-stakeholder>
    <sbx-button title="Cancel" theme="secondary" (click)="closeModal()"> </sbx-button>
  </sbx-modal-footer>
</ng-template>

<ng-container *ngIf="this.modalOpen">
  <ng-template *ngTemplateOutlet="content"></ng-template>
</ng-container>
