import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SbxHttpClient } from '@/core/http';
import { BackendLocation } from '@/core/http';
import { BehaviorSubject, take, timer } from 'rxjs';

const API_VERSION = '1';
const EMAIL_REGEX =
  /^[_a-z0-9-+]+(\.[_a-z0-9-+]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
const ERROR_MESSAGE =
  'An error occurred and has been reported. Please try again later.';
// Specifies how long resend button should be disabled in seconds
const RESEND_AVAILABLE_IN = 30;

export interface IPostEmailResponse {
  message?: string;
}

export interface IPostPhoneResponse {
  message?: string;
}

@Component({
  selector: 'sbx-verification',
  templateUrl: './sbx-verification.component.html',
  styleUrls: ['./sbx-verification.component.scss'],
})
export class SbxVerificationComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  fields;
  loading$ = new BehaviorSubject<boolean>(false);
  message$ = new BehaviorSubject<string>('');
  error = false;
  availableIn = null;
  sent = false;

  constructor(
    private sbxHttp: SbxHttpClient,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
    protected changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.fields = {
      email: {
        key: 'recoveryEmail',
        value: null,
        placeholder: 'Enter Email Address',
        disabled: true,
      },
      phone: {
        key: 'recoveryPhone',
        value: null,
        placeholder: 'Enter U.S Phone Number',
        disabled: true,
      },
      code: {
        key: 'verificationCode',
        value: null,
        placeholder: 'Enter Verification Code',
        disabled: true,
      },
    };
  }

  resetEmail() {
    this.formControl.setValue(null);
  }

  resetPhone() {
    this.formControl.setValue(null);
  }

  sendEmail() {
    this.postEmail();
  }

  sendPhone() {
    this.postPhone();
  }

  sendCode() {
    this.postCode();
  }

  handleEmailChange(value) {
    this.sent = false;
    if (EMAIL_REGEX.test(value)) {
      this.fields.email.value = value;
      this.fields.email.disabled = false;
    } else {
      this.fields.email.disabled = true;
    }
  }

  handlePhoneChange(value) {
    this.sent = false;
    if (value) {
      this.fields.phone.value = value;
      this.fields.phone.disabled = false;
    } else {
      this.fields.phone.disabled = true;
    }
  }

  handleVerificationChange(value) {
    if ((value && this.fields.email.value) || (value && this.fields.phone.value)) {
      this.fields.code.value = value;
      this.fields.code.disabled = false;
    } else {
      this.fields.code.value = null;
      this.fields.code.disabled = true;
    }
  }

  postEmail() {
    this.availableIn = 0;
    this.message$.next('');
    const onBehalf = this.backendLocation.onBehalf(API_VERSION);
    this.sbxHttp
      .entity(API_VERSION)
      .put(`stakeholders/${onBehalf}/emails`, {
        params: {
          email: this.fields.email.value,
        },
      })
      .subscribe(
        (res: IPostEmailResponse) => {
          this.message$.next(res.message);
          this.sent = true;
          this.setSendButtonTimer();
        },
        (errors) => {
          this.error = true;
          this.message$.next(errors.error.error || ERROR_MESSAGE);
        },
      );
  }

  postPhone() {
    this.availableIn = 0;
    this.message$.next('');
    const onBehalf = this.backendLocation.onBehalf(API_VERSION);
    this.sbxHttp
      .entity(API_VERSION)
      .put(`stakeholders/${onBehalf}/phones`, {
        params: {
          phoneNumber: this.fields.phone.value,
        },
      })
      .subscribe(
        (res: IPostPhoneResponse) => {
          this.message$.next(res.message);
          this.sent = true;
          this.setSendButtonTimer();
        },
        (errors) => {
          this.error = true;
          this.message$.next(errors.error.error || ERROR_MESSAGE);
        },
      );
  }

  postCode() {
    let payload;
    if (this.field.key === this.fields.email.key) {
      payload = {
        email: this.fields.email.value,
        token: this.fields.code.value,
      };
    }
    if (this.field.key === this.fields.phone.key) {
      payload = {
        phone: this.fields.phone.value,
        token: this.fields.code.value,
      };
    }
    const onBehalf = this.backendLocation.onBehalf(API_VERSION);
    this.message$.next('');
    this.loading$.next(true);
    this.sbxHttp
      .entity(API_VERSION)
      .put(`stakeholders/${onBehalf}/verifyPhone`, {
        params: payload,
      })
      .subscribe(
        () => {
          if (this.field.key === this.fields.email.key) {
            this.formControl.setValue(this.fields.email.value);
          }
          if (this.field.key === this.fields.phone.key) {
            this.formControl.setValue(this.fields.phone.value);
          }
          this.loading$.next(false);
        },
        (errors) => {
          this.loading$.next(false);
          this.error = true;
          this.message$.next(errors.error.error || ERROR_MESSAGE);
        },
      );
  }

  setSendButtonTimer() {
    timer(0, 1000)
      .pipe(take(RESEND_AVAILABLE_IN + 1))
      .subscribe((value) => {
        this.availableIn = RESEND_AVAILABLE_IN - value;
        this.changeDetector.detectChanges();
      });
  }

  getSendButtonTitle() {
    if (this.availableIn === null) {
      return 'Send';
    }

    if (this.availableIn === 0) {
      return 'Resend';
    }

    return `Resend in ${this.availableIn} seconds`;
  }
}
