import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SbxCompiledContentComponent } from './compiled-content.component';
import { SbxDepartmentsAdminComponent } from './departments-admin.component';
import { SbxDocumentDownloadDropdownComponent } from './document-download-dropdown.component';
import { SbxDocumentDownloadComponent } from './document-download.component';
import { EditTermsModalServiceProvider } from './edit-terms-modal.service';
import { SbxGenerateCustomizationReportComponent } from './generate-customization-report.component';
import { SbxHrTicketsAdminComponent } from './hr-tickets-admin.component';
import { SbxPdfUploadComponent } from './pdf-upload.component';
import { ProcessModelServiceProvider } from './process-model.service';
import { ProcessStatusServiceProvider } from './process-status.service';
import { ProcessUrlInfoServiceProvider } from './process-url-info.service';
import { PromiseErrorCatcherServiceProvider } from './promise-error-catcher.service';
import { ReviewAndEditDocumentsModelServiceProvider } from './review-and-edit-documents-model.service';
import { SbxContextSubprocButtonComponent } from './sbx-context-subproc-button.component';
import { SelectEditResolutionModalServiceProvider } from './select-edit-resolution-modal.service';
import { StakeholderInviteModalServiceProvider } from './stakeholder-invite-modal.service';
import { SbxWorkitemRemindButtonComponent } from './workitem-remind-button.component';
import { SbxRequireVerificationComponent } from './sbx-require-verification.component';
import { ProcessButtonModelServiceProvider } from './process-button-model.service';
import { SerializeAndSubmitProcessFormServiceProvider } from './serialize-and-submit-process-form.service';
import { SbxBulkSharingComponent } from './sbx-bulk-sharing.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SbxCompiledContentComponent,
    SbxDepartmentsAdminComponent,
    SbxHrTicketsAdminComponent,
    SbxPdfUploadComponent,
    SbxDocumentDownloadComponent,
    SbxDocumentDownloadDropdownComponent,
    SbxGenerateCustomizationReportComponent,
    SbxWorkitemRemindButtonComponent,
    SbxRequireVerificationComponent,
    SbxContextSubprocButtonComponent,
    SbxBulkSharingComponent,
  ],
  exports: [
    SbxCompiledContentComponent,
    SbxDepartmentsAdminComponent,
    SbxHrTicketsAdminComponent,
    SbxPdfUploadComponent,
    SbxDocumentDownloadComponent,
    SbxDocumentDownloadDropdownComponent,
    SbxGenerateCustomizationReportComponent,
    SbxWorkitemRemindButtonComponent,
    SbxRequireVerificationComponent,
    SbxContextSubprocButtonComponent,
    SbxBulkSharingComponent,
  ],
  providers: [
    ProcessUrlInfoServiceProvider,
    ProcessStatusServiceProvider,
    ProcessModelServiceProvider,
    ProcessButtonModelServiceProvider,
    StakeholderInviteModalServiceProvider,
    ReviewAndEditDocumentsModelServiceProvider,
    SelectEditResolutionModalServiceProvider,
    EditTermsModalServiceProvider,
    PromiseErrorCatcherServiceProvider,
    SerializeAndSubmitProcessFormServiceProvider,
  ],
})
export class UpgradedModule {}
