import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { SbxAuthGuard } from './auth.guard';
import { SbxLogoutRedirectGuard } from '@/sbx-logout-redirect/sbx-logout-redirect.guard';
import { SbxErrorPageComponent } from '@/sbx-error-page/sbx-error-page.component';
import {
  legend1933Resolver,
  legendGroupsResolver,
} from '@/sbx-stock-certificate-legend-management/stock-certificate-legend-resolver';
import { authRoutes } from '@/sbx-auth/sbx-auth-routes';
import { SbxForbiddenAccessPageComponent } from '@/sbx-forbidden-access-page/sbx-forbidden-access-page.component';
import { SbxAuthService } from '@/sbx-auth/sbx-auth.service';

const entityRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../sbx-dashboard/sbx-dashboard.module').then((m) => m.SbxDashboardModule),
  },
  {
    path: 'investments',
    loadChildren: () =>
      import('../sbx-investment/sbx-investment-lazy.module').then(
        (m) => m.SbxInvestmentLazyModule,
      ),
  },
  {
    path: 'hr',
    loadChildren: () =>
      import('../sbx-hr-admin/sbx-hr-admin-lazy.module').then(
        (m) => m.SbxHrAdminLazyModule,
      ),
  },
  {
    path: 'vesting-schedules',
    loadChildren: () =>
      import('../sbx-vesting-schedules/sbx-vesting-schedules.module').then(
        (m) => m.SbxVestingSchedulesModule,
      ),
  },
  {
    path: 'employee-report',
    loadChildren: () =>
      import('../sbx-employee-report/sbx-employee-report.module').then(
        (m) => m.SbxEmployeeReportModule,
      ),
  },
  {
    path: 'asc718-management',
    loadChildren: () =>
      import('../sbx-asc718-admin/sbx-asc718-admin-lazy.module').then(
        (m) => m.SbxAsc718AdminLazyModule,
      ),
  },
  {
    path: 'cap-table',
    loadChildren: () =>
      import('../sbx-captable/sbx-captable.module').then((m) => m.SbxCaptableModule),
  },
  {
    path: 'stock-certificate-legend-management',
    resolve: {
      legendGroups: legendGroupsResolver,
      legend1933: legend1933Resolver,
    },
    loadComponent: () =>
      import(
        '../sbx-stock-certificate-legend-management/sbx-stock-certificate-legend-management.component'
      ).then((c) => c.SbxStockCertificateLegendManagementComponent),
  },
  {
    path: 'ownership-visualization',
    loadChildren: () =>
      import('../sbx-ownership-visualization/sbx-ownership-visualization.module').then(
        (m) => m.SbxOwnershipVisualizationModule,
      ),
  },
  {
    path: '409A-valuation',
    loadChildren: () =>
      import('../sbx-409a-valuation/sbx-409a-valuation.module').then(
        (m) => m.Sbx409AValuationModule,
      ),
  },
  {
    path: 'slt-compensation-report',
    loadChildren: () =>
      import('../sbx-slt-compensation-report/sbx-slt-compensation-report.module').then(
        (m) => m.SbxSLTCompensationReportModule,
      ),
  },
  {
    path: 'promise-report',
    loadChildren: () =>
      import('../sbx-promise-report/sbx-promise-report.module').then(
        (m) => m.SbxPromiseReportModule,
      ),
  },
  {
    path: 'plan-utilization',
    loadChildren: () =>
      import('../sbx-equity/sbx-sip-utilization/sbx-sip-utilization.module').then(
        (m) => m.SbxSipUtilzationModule,
      ),
  },
  {
    path: 'activity-logs',
    loadChildren: () =>
      import('../sbx-sboobx-activity-logs/sbx-shoobx-activity-logs.module').then(
        (m) => m.SbxShoobxActivityLogsModule,
      ),
  },
  {
    path: 'gusto',
    loadChildren: () =>
      import('../sbx-gusto/sbx-gusto.module').then((m) => m.SbxGustoModule),
  },
  {
    path: 'workflows',
    loadChildren: () =>
      import('../sbx-workflows/sbx-workflows.module').then((m) => m.SbxWorkflowModule),
  },
  {
    path: 'stakeholders',
    loadChildren: () =>
      import('../sbx-stakeholder/sbx-stakeholder.module').then(
        (m) => m.SbxStakeholderModule,
      ),
  },
  {
    path: 'nda',
    loadChildren: () =>
      import('../sbx-nda-report/sbx-nda-report.module').then(
        (m) => m.SbxNDAReportModule,
      ),
  },
  {
    path: 'invalid-emails',
    loadChildren: () =>
      import('../sbx-blacklist-manager/sbx-blacklist-manager.module').then(
        (m) => m.SbxBlacklistManagerModule,
      ),
  },
  {
    path: 'employees',
    loadChildren: () =>
      import('../sbx-employee/sbx-employee.module').then((m) => m.SbxEmployeeModule),
  },
  {
    path: 'stock-ledger',
    loadChildren: () =>
      import('../sbx-stock-ledger/sbx-stock-ledger.module').then(
        (m) => m.SbxStockLedgerModule,
      ),
  },
  {
    path: 'equity-transactions',
    loadChildren: () =>
      import('../sbx-equity-transactions/sbx-equity-transactions.module').then(
        (m) => m.SbxEquityTransactionsModule,
      ),
  },
  {
    path: 'rule-701-report',
    loadChildren: () =>
      import('../sbx-rule-701-report/sbx-rule-701-report.module').then(
        (m) => m.SbxRule701ReportModule,
      ),
  },
  {
    path: 'investor-updates',
    loadChildren: () =>
      import('../sbx-investor-communications/sbx-investor-communications.module').then(
        (m) => m.SbxInvestorCommunicationsModule,
      ),
  },
  {
    path: 'relationships',
    loadChildren: () =>
      import('../sbx-relationship/sbx-relationship.module').then(
        (m) => m.SbxRelationshipModule,
      ),
  },
  {
    path: 'equity',
    loadChildren: () =>
      import('../sbx-equity/sbx-equity.module').then((m) => m.SbxEquityModule),
  },
  {
    path: 'support-tickets',
    loadChildren: () =>
      import('../sbx-support-ticket/sbx-support-ticket.module').then(
        (m) => m.SbxSupportTicketModule,
      ),
  },
  {
    path: 'track83b',
    loadChildren: () =>
      import('../sbx-track83b/sbx-track83b.module').then((m) => m.SbxTrack83bModule),
  },
  {
    path: 'iso100k-report',
    loadChildren: () =>
      import('../sbx-iso-100k-report/sbx-iso-100k-report.module').then(
        (m) => m.SbxIso100kReportModule,
      ),
  },
  {
    path: 'fundraising',
    loadChildren: () =>
      import('../sbx-fundraising/sbx-fundraising.module').then(
        (m) => m.SbxFundraisingModule,
      ),
  },
  {
    path: 'stakeholders',
    loadChildren: () =>
      import('../sbx-profile/sbx-profile.module').then((m) => m.SbxProfileModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'billing-tickets',
    loadChildren: () =>
      import('../sbx-billing-ticket/sbx-billing-ticket.module').then(
        (m) => m.SbxBillingTicketModule,
      ),
  },
  {
    path: 'shapproval-settings',
    loadComponent: () =>
      import('../sbx-stakeholder-approval/sbx-stakeholder-approval.component').then(
        (m) => m.SbxStakeholderApprovalComponent,
      ),
  },
  {
    path: 'relationship-notifications',
    loadComponent: () =>
      import(
        '../sbx-relationship-notifications/sbx-relationship-notifications.component'
      ).then((m) => m.SbxRelationshipNotificationsComponent),
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('../sbx-payments/sbx-payments.module').then((m) => m.SbxPaymentsModule),
  },
  {
    path: 'tax-report',
    loadChildren: () =>
      import('../sbx-tax/sbx-tax-lazy.module').then((m) => m.SbxTaxLazyModule),
  },
  {
    path: 'price-per-share-report',
    loadChildren: () =>
      import('../sbx-price-per-share/sbx-price-per-share.module').then(
        (m) => m.SbxPricePerShareModule,
      ),
  },
  {
    path: 'hr-upload-history',
    loadChildren: () =>
      import('../sbx-hr-upload-history/sbx-hr-upload-history.module').then(
        (m) => m.SbxHrUploadHistoryModule,
      ),
  },
];

const routes: Routes = [
  {
    path: ':entity/forbidden',
    component: SbxForbiddenAccessPageComponent,
  },
  {
    path: 'forbidden',
    component: SbxForbiddenAccessPageComponent,
  },
  {
    path: 'auth',
    providers: [SbxAuthService],
    children: authRoutes,
  },
  {
    path: 'logout',
    canActivate: [SbxLogoutRedirectGuard],
    children: [],
  },
  {
    path: ':entity/logout',
    canActivate: [SbxLogoutRedirectGuard],
    children: [],
  },
  {
    path: 'error',
    component: SbxErrorPageComponent,
  },
  {
    path: 'entities',
    canActivate: [SbxAuthGuard],
    loadChildren: () =>
      import('../sbx-landing-page/sbx-landing-page.module').then(
        (m) => m.SbxLandingPageModule,
      ),
    data: {
      hideBackground: true,
    },
  },
  {
    path: ':entity',
    canActivate: [SbxAuthGuard],
    children: entityRoutes,
  },
  {
    path: ':onbehalf/:entity',
    canActivate: [SbxAuthGuard],
    children: entityRoutes,
  },
  {
    path: '',
    redirectTo: 'entities',
    pathMatch: 'full',
  },
];

const config: ExtraOptions = {
  enableTracing: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  providers: [SbxAuthGuard],
  exports: [RouterModule],
})
export class SbxAppRouterModule {}
