import { Component, Input, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { TaxAdjustmentOptions } from '@shoobx/types/webapi-v2';
import { SbxTaxCustomEntryModalService } from '../sbx-tax-custom-entry-modal/sbx-tax-custom-entry-modal.service';
import { SbxFormModalService } from '@/shared/sbx-form-modal/sbx-form-modal.service';

interface ITaxRow {
  adjustment: TaxAdjustmentOptions;
  group: string;
  groupTaxCode: string;
  subfield: number;
  canRemove: boolean;
  valid: boolean;
  showError: boolean;
}

/**
 *  Adjust Taxes Component
 *
 *  @ngdoc component
 *  @name sbx-tax-adjust-event-taxes/component:SbxTaxEventAdjustTaxesComponent
 *  @description
 *  component for updating a list of tax entries
 *
 *
 *  @param adjustments - optional list of initial taxes
 *  @param canAdd - can add new tax items?
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-tax-adjust-event-taxes
 *          [adjustments]="adjustments"
 *          [canAdd]="true"
 *          ></sbx-properties-list>
 *      `,
 *    })
 */
@Component({
  selector: 'sbx-tax-adjust-event-taxes',
  templateUrl: './sbx-tax-adjust-event-taxes.component.html',
  styleUrls: ['sbx-tax-adjust-event-taxes.component.scss'],
})
export class SbxTaxAdjustEventTaxesComponent implements OnInit {
  @Input() adjustments: TaxAdjustmentOptions[];
  @Output() adjustmentsChange: EventEmitter<TaxAdjustmentOptions[]> =
    new EventEmitter();
  @Input() canAdd: boolean = true;
  @Output() validation: EventEmitter<boolean> = new EventEmitter();

  totalTaxes: number = null;

  rows: ITaxRow[];
  formValid: boolean = true;
  initialValues: number[];

  constructor(
    @Inject(SbxTaxCustomEntryModalService)
    public sbxTaxCustomEntryModalService: SbxTaxCustomEntryModalService,
    @Inject(SbxFormModalService)
    public sbxFormModalService: SbxFormModalService,
  ) {}

  ngOnInit() {
    this.adjustments = this.adjustments.map(
      (adjustment) =>
        <TaxAdjustmentOptions>{
          ...adjustment,
          override:
            adjustment.canEdit && adjustment.override === null
              ? adjustment.tax
              : adjustment.override,
        },
    );

    this.rows = this.makeRows(this.adjustments);
    this.initialValues = this.getCurrentRowValues();
    this.validateForm();
    this.updateTotalTaxes();
  }

  makeRows(adjustments: TaxAdjustmentOptions[]): ITaxRow[] {
    // Create rows to display from given adjustments.
    //
    // Some adjustments may have a .group name.  For these, we want to
    // create an extra row with title set to group name, followed by
    // rows of adjustments for that group.
    //
    // An example would be "Country - United States" group followed
    // by "Federal", "State", "Social Security" and "Medicare" tax rows
    // displayed as subfields.
    //
    // Finally, at the bottom of the list we want to have rows
    // for every adjustment without a group.

    const groupNames = [
      ...new Set(adjustments.filter((i) => i.group).map((i) => i.group)),
    ];

    const groupRows = [
      ...groupNames.flatMap((groupName) => [
        // Row for the group title
        <ITaxRow>{
          adjustment: null,
          group: groupName,
          groupTaxCode: adjustments.reduce(
            (code, a) => (a.group === groupName ? code || a.groupTaxCode : code),
            null,
          ),
          subfield: 0,
          canRemove: adjustments.every(
            (adjustment) => adjustment.canRemove || adjustment.group !== groupName,
          ),
        },
        // Followed by rows of tax adjustments for that group (displayed as subfields)
        ...adjustments
          .filter((adjustment) => adjustment.group === groupName)
          .map(
            (adjustment) =>
              <ITaxRow>{
                adjustment: adjustment,
                group: groupName,
                groupTaxCode: adjustment.groupTaxCode,
                subfield: 1,
                canRemove: false,
              },
          ),
      ]),
    ];

    const ungrouppedRows = adjustments
      .filter((adjustment) => !adjustment.group)
      .map(
        (adjustment) =>
          <ITaxRow>{
            adjustment: adjustment,
            group: null,
            groupTaxCode: null,
            subfield: 0,
            canRemove: adjustment.canRemove,
          },
      );

    const rows = [...groupRows, ...ungrouppedRows];
    return rows;
  }

  getCurrentRowValues() {
    return this.rows.map((row) => (row.adjustment ? row.adjustment.override : null));
  }

  validateForm() {
    this.rows.forEach(this.validateRow);
    const valid = this.rows.every((row) => row.valid);
    const newValues = this.getCurrentRowValues();
    const sameValues =
      newValues.length === this.initialValues.length &&
      newValues.every((element, index) => element === this.initialValues[index]);
    this.formValid = valid;
    this.validation.emit(valid && !sameValues);
  }

  validateRow(row: ITaxRow) {
    row.valid =
      row.adjustment === null ||
      !row.adjustment.canEdit ||
      (row.adjustment.override !== null && row.adjustment.override >= 0);
  }

  handleFocus(row: ITaxRow) {
    row.showError = false;
  }

  handleBlur(row: ITaxRow) {
    row.showError = !row.valid;
  }

  handleChange(row: ITaxRow, value: number) {
    const changed = row.adjustment.override !== value;
    row.adjustment.override = value;
    this.validateForm();
    if (changed) {
      this.adjustmentsChange.emit(this.adjustments);
    }
    this.updateTotalTaxes();
  }

  updateTotalTaxes() {
    if (!this.formValid) {
      this.totalTaxes = null;
      return;
    }
    const taxes = this.rows.filter((row) => row.adjustment !== null);
    this.totalTaxes = taxes.reduce(
      (sum, row) =>
        sum +
        (row.adjustment.override === null
          ? row.adjustment.tax
          : row.adjustment.override),
      0,
    );
  }

  removeRow(row: ITaxRow) {
    if (row.group && row.adjustment === null) {
      // remove entire group
      this.rows = this.rows.filter((r) => r.group !== row.group);
    } else {
      this.rows = this.rows.filter((r) => r !== row);
      if (
        row.group &&
        this.rows.every((r) => r.group !== row.group || r.adjustment === null)
      ) {
        // we've removed the last row with adjustment, remove the group row too
        this.rows = this.rows.filter((r) => r.group !== row.group);
      }
    }
    this.adjustments = this.rows
      .filter((r) => r.adjustment !== null)
      .map((r) => r.adjustment);
    this.validateForm();
    this.adjustmentsChange.emit(this.adjustments);
    this.updateTotalTaxes();
  }

  validateNewTaxEntries(entries: TaxAdjustmentOptions[]): void {
    entries.forEach((entry: TaxAdjustmentOptions) => {
      // Check if we're not trying to add a group that is already there.
      // Note that we also do not support adding new items to existing groups.
      if (
        entry.group &&
        this.rows.some((row) =>
          row.adjustment === null && entry.taxCode
            ? entry.groupTaxCode === row.groupTaxCode
            : entry.group === row.group,
        )
      ) {
        throw new Error(`Tax already added: ${entry.group}`);
      }

      // Check if entry is not a duplicate of any existing entry or group.
      if (
        this.rows.some((row) =>
          entry.taxCode
            ? entry.taxCode ===
              (row.adjustment === null ? row.groupTaxCode : row.adjustment.taxCode)
            : entry.title ===
              (row.adjustment === null ? row.group : row.adjustment.title),
        )
      ) {
        throw new Error(`Tax already added: ${entry.title}`);
      }
    });
  }

  async addCustomEntry() {
    const data = {
      title: 'Custom Entry',
      topMessageText:
        'When adding a custom entry (country, state or other)' +
        ' you are responsible for calculating and inputting' +
        ' the applicable taxes.',
      topMessageTemplate: null,
      topMessageTemplateContext: null,
      validateEntries: (e) => this.validateNewTaxEntries(e),
      url: 'tax/custom_entry',
    };

    try {
      const result = await this.sbxTaxCustomEntryModalService.open({ data }).result;
      const newEntries: TaxAdjustmentOptions[] = result.result;
      newEntries.forEach((e) => {
        e.override ||= 0.0;
      });
      this.adjustments = [...this.adjustments, ...newEntries];
    } catch {
      return; // dialog closed, nothing more to do
    }

    this.rows = this.makeRows(this.adjustments);
    this.validateForm();
    this.adjustmentsChange.emit(this.adjustments);
    this.updateTotalTaxes();
  }
}
