import angular from 'angular';

/**
 * @ngdoc service
 * @name sb.lib.employee.EmployeeDetails
 *
 * @description
 * Use this service to get info about employees
 */
export const EmployeeDetails = [
  'SimpleHTTPWrapper',
  'BackendLocation',
  function (SimpleHTTPWrapper, BackendLocation) {
    /**
     * @ngdoc method
     * @name employeedetails
     * @methodOf sb.lib.employee.EmployeeDetails
     *
     * @param {object} data Data to send to the server.
     *
     * @returns {promise} Promise which resolves with positive response and
     *    rejects with negative response.
     */
    return (stakeholderId) =>
      SimpleHTTPWrapper({
        url:
          `${BackendLocation.entity(1)}stakeholders/` +
          `${stakeholderId}/employee-profile`,
        method: 'GET',
      });
  },
];

/**
 * @ngdoc component
 * @name sb.lib.stakeholder.component:sbEmployeeProfilePage
 *
 * @description
 * Shows the employee profile page (windows 10 style blocks)
 *
 * @param {string} stakeholderId ID of a stakeholder.
 *
 * @param {bool} showEditLink show link to "Edit my profile" (usually if current
 * user is looking at his own profile)
 *
 * @param {bool} showFullProfileLink Whether to show the link to the full profile
 */
export function sbEmployeeProfilePage() {
  return {
    restrict: 'E',
    template: require('./templates/employee-profile.html'),
    scope: {
      stakeholderId: '<stakeholderId',
      showEditLink: '<showEditLink',
      showFullProfileLink: '<showFullProfileLink',
      showOrgChartLink: '<showOrgChartLink',
    },
    controller: [
      '$scope',
      'EmployeeDetails',
      'SbxUrlService',
      function ($scope, EmployeeDetails, SbxUrlService) {
        EmployeeDetails($scope.stakeholderId).then(
          (employeeObj) => {
            $scope.profileUrl = SbxUrlService.canonicalUrl(
              ['stakeholders', $scope.stakeholderId],
              {
                withSpaPrefix: true,
              },
            );

            $scope.employee = employeeObj;
            $scope.twitterUrl = 'http://twitter.com/' + $scope.employee.twitter;
            if ($scope.employee.linkedin) {
              if (
                $scope.employee.linkedin.startsWith('http://') ||
                $scope.employee.linkedin.startsWith('https://')
              ) {
                $scope.linkedinUrl = $scope.employee.linkedin;
              } else {
                $scope.linkedinUrl = 'http://' + $scope.employee.linkedin;
              }
            }
          },
          (error) => {
            const content = angular.isString(error) ? error : 'There was an error.';
            $scope.message = { type: 'danger', content };
          },
        );
      },
    ],
  };
} // end sbEmployeeProfilePage
