<div
  class="sbx-responsive-container"
  [ngStyle]="{
    'height.px': headerSticky && !isMobile && data?.length ? getTableHeight() : 'auto'
  }"
>
  <table
    *ngIf="(data && data.length) || headersOnly"
    [ngClass]="{ condensed: condensed }"
  >
    <thead *ngIf="!noHeaders">
      <tr class="grouped-header" *ngIf="groups">
        <ng-container *ngFor="let c of columnGroups">
          <th
            [attr.colspan]="c.colspan"
            [ngClass]="[
              getHighlightColorClass(c.key)
                ? 'sbx-' + getHighlightColorClass(c.key) + '-highlight'
                : ''
            ]"
          >
            {{ c.title }}
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let c of columns; let columnIndex = index">
          <th
            [ngClass]="[
              c.sortable ? 'has-sorters' : '',
              getHighlightColorClass(c.group)
                ? 'sbx-' + getHighlightColorClass(c.group) + '-highlight'
                : '',
              (firstColumnSticky && columnIndex === 0) ||
              (lastColumnSticky && columnIndex === columns.length - 1)
                ? 'sbx-sticky-col'
                : '',
              headerSticky && !isMobile ? 'sbx-sticky-header' : ''
            ]"
            [ngStyle]="{
              width: c.width,
              'text-align': c.align !== 'left' ? 'center' : 'left'
            }"
            (click)="onSort(c.key)"
          >
            <div class="sbx-th-container">
              <ng-template
                *ngTemplateOutlet="headerCellTemplate; context: { $implicit: c }"
              ></ng-template>

              <sbx-popover
                class="sbx-help-icon-container"
                [sbxPopoverContent]="c.helpText ? c.helpText : c.helpTemplate"
                *ngIf="c.helpText || c.helpTemplate"
              >
                <sbx-icon class="sbx-help-icon" type="help"></sbx-icon>
              </sbx-popover>

              <div
                class="sbx-sort-icon-container"
                *ngIf="c.sortable"
                [ngSwitch]="c.sortOrder"
              >
                <sbx-icon type="sortAsc" *ngSwitchCase="'asc'"></sbx-icon>
                <sbx-icon type="sortDesc" *ngSwitchCase="'desc'"></sbx-icon>
              </div>
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let d of data; let i = index; trackBy: rowTrackBy">
        <tr
          [ngClass]="{
            strip: d.rowIndex === undefined ? i % 2 === 0 : d.rowIndex % 2 === 0,
            'bordered-top': d.rowspan === 0,
            'has-child-row': d.children
          }"
        >
          <ng-container *ngFor="let c of d.columnConfig || columns; let cIndex = index">
            <ng-container
              *ngTemplateOutlet="
                cellTemplate;
                context: {
                  column: c,
                  row: d,
                  rowIndex: i,
                  totalRows: data.length,
                  colIndex: cIndex
                }
              "
            ></ng-container>
          </ng-container>
        </tr>

        <tr
          *ngFor="let child of d.children; let j = index"
          [ngClass]="{
            strip: i % 2 === 0,
            'child-row': d.children,
            'child-row-last': d.children.length - 1 === j
          }"
        >
          <ng-container
            *ngFor="let c of child.columnConfig || columns; let cIndex = index"
          >
            <ng-container
              *ngTemplateOutlet="
                cellTemplate;
                context: {
                  column: c,
                  row: child,
                  rowIndex: i,
                  childRowIndex: j,
                  totalRows: data.length,
                  colIndex: cIndex
                }
              "
            ></ng-container>
          </ng-container>
        </tr>
      </ng-container>

      <tr *ngIf="!data">
        <td [attr.colspan]="columns.length">No data</td>
      </tr>
    </tbody>

    <tfoot></tfoot>
  </table>

  <div *ngIf="(!data || !data.length) && !headersOnly" class="sbx-no-rows">
    <i *ngIf="!placeholderText && !placeholderTemplate"
      >There are currently no {{ itemPlaceholderName }}.</i
    >
    <i *ngIf="placeholderText"> {{ placeholderText }} </i>
    <ng-container *ngIf="placeholderTemplate">
      <ng-container *ngTemplateOutlet="placeholderTemplate"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #headerCellTemplate let-column>
  <div class="sbx-group-title">
    <ng-container *ngIf="column.headerTemplate">
      <ng-template
        *ngTemplateOutlet="
          column.headerTemplate;
          context: { $implicit: column.headerTemplateContext }
        "
      ></ng-template>
    </ng-container>

    <ng-container *ngIf="!column.headerTemplate">
      <span>{{ column.title }}</span>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #cellTemplate
  let-column="column"
  let-row="row"
  let-rowIndex="rowIndex"
  let-childRowIndex="childRowIndex"
  let-columnIndex="colIndex"
>
  <td
    *ngIf="!(column.multiRowPossible && row.rowspan === 0)"
    [attr.rowspan]="column.multiRowPossible ? row.rowspan : 1"
    [attr.colspan]="column.colspan || 1"
    [ngClass]="[
      enableRowKey &&
      !row[enableRowKey] &&
      !column.alwaysEnabled &&
      column.key !== enableRowKey
        ? 'disabled'
        : '',
      getHighlightColorClass(column.group)
        ? 'sbx-' + getHighlightColorClass(column.group) + '-highlight'
        : '',
      (firstColumnSticky && columnIndex === 0) ||
      (lastColumnSticky && columnIndex === columns.length - 1)
        ? 'sbx-sticky-col'
        : '',
      lastColumnSticky && columnIndex === columns.length - 2
        ? 'sbx-no-right-border'
        : '',
      row.rowspan === 0 && row.rowIndex === 0 && columnIndex === 1
        ? 'sbx-not-first'
        : ''
    ]"
    [ngStyle]="{ width: column.width, 'text-align': column.align || 'left' }"
  >
    <ng-container *ngIf="column.template">
      <ng-template
        *ngTemplateOutlet="
          column.template;
          context: {
            $implicit: row[column.key],
            row: row,
            column: column,
            rowIndex: rowIndex,
            childRowIndex: childRowIndex,
            totalRows: data.length,
            templateContext: column.templateContext || {}
          }
        "
      ></ng-template>
    </ng-container>

    <ng-container *ngIf="!column.template">
      {{ row[column.key] }}
    </ng-container>
  </td>
</ng-template>
