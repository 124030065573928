import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxTabsLayoutComponent } from './sbx-tabs-layout.component';
import { RouterModule } from '@angular/router';
import { SbxTopNavModule } from '../sbx-top-nav/sbx-top-nav.module';
import { SbxSideNavModule } from '../sbx-side-nav/sbx-side-nav.module';
import { SbxPageHeaderModule } from '../sbx-page-header/sbx-page-header.module';
import { SbxGridModule } from '@/shared/sbx-grid/sbx-grid.module';
import { SbxResponsiveNavModule } from '../sbx-responsive-nav/sbx-responsive-nav.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SbxTopNavModule,
    SbxSideNavModule,
    SbxPageHeaderModule,
    SbxGridModule,
    SbxResponsiveNavModule,
  ],
  declarations: [SbxTabsLayoutComponent],
  exports: [SbxTabsLayoutComponent],
})
export class SbxTabsLayoutModule {}
