import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ProfileGroupsComponent } from './column-templates/profile-groups.component';
import { BoolCheckComponent } from './column-templates/bool-check.component';
/**
 *  Shoobx Table
 *
 *  Complex template usage:
 *    @Component({
 *      template: `
 *        <sbx-table [columns]="table.columns" [data]="table.data" [rowTrackBy]="table.trackBy"></sbx-table>
 *        <ng-template #emailTemplate let-item>
 *          <a href="mailto:{{item}}">{{item}}</a>
 *        </ng-template>
 *      `,
 *    })
 *    class MyComponent {
 *      @ViewChild('emailTemplate') emailTemplate: TemplateRef<any>;
 *
 *      constructor() {
 *        this.table = {}

 *        this.table['data'] = [{
 *          name: 'Hello',
 *          surname: 'World',
 *          email: 'hello@world1.com',
 *          age: 23
 *        }]

 *        this.table['columns'] = [{
 *          key: 'name',
 *          title: 'Name',
 *        }, {
 *          key: 'surname',
 *          title: 'Surname',
 *        }, {
 *          key: 'email',
 *          title: 'Email',
 *          template: this.emailTemplate
 *        }, {
 *          key: 'age',
 *          title: 'Age',
 *        }]
 *
 *       this.table['trackBy'] = (index, item) => item.email;
 *
 *      }
 *    }
 */

export type ISbxTableColumns = ISbxTableColumn[];

export interface ISbxTableColumn {
  key: string;
  title?: string;
  helpText?: TemplateRef<unknown> | string;
  helpTemplate?: TemplateRef<unknown>;
  headerTemplate?: TemplateRef<unknown>;
  headerTemplateContext?:
    | Record<string, unknown>
    | ((row: ISbxTableRow) => Record<string, unknown>);
  template?: TemplateRef<unknown>;
  templateContext?:
    | Record<string, unknown>
    | ((row: ISbxTableRow) => Record<string, unknown>);
  templateName?: string;
  sortOrder?: 'asc' | 'desc';
  sortable?: boolean;
  group?: string;
  // Any css valid number
  width?: string;
  align?: 'left' | 'center' | 'right';
  alwaysEnabled?: boolean;
  colspan?: number;
  multiRowPossible?: boolean;
}

export type ISbxTableData = ISbxTableRow[];

export interface ISbxTableRow {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  columnConfig?: ISbxTableColumns;
  children?: ISbxTableRow[];
}

export type ISbxTableGroups = ISbxTableGroup[];

export interface ISbxTableGroup {
  key: string;
  title: string;
  highlightColor?: 'gray' | 'green';
}

@Component({
  selector: 'sbx-table',
  templateUrl: './sbx-table.component.html',
  styleUrls: ['./sbx-table.component.scss'],
})
export class SbxTableComponent implements OnInit, OnChanges {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
  ) {}

  @Input() data: ISbxTableData;
  @Input() rowTrackBy: (index: number, object: unknown) => void;
  @Input() columns: ISbxTableColumns;
  @Input() groups: ISbxTableGroups;

  @Input() enableRowKey = '';
  @Input() headersOnly = false;
  @Input() noHeaders = false;
  @Input() itemPlaceholderName = 'items';
  @Input() placeholderText = '';
  @Input() placeholderTemplate: TemplateRef<unknown>;
  @Input() condensed = false;
  @Input() firstColumnSticky = false;
  @Input() lastColumnSticky = false;
  @Input() headerSticky = false;
  @Input() bottomMessageHeight = 0;
  @Output() sort: EventEmitter<{
    key: ISbxTableColumn['key'];
    order: ISbxTableColumn['sortOrder'];
  }> = new EventEmitter();

  columnGroups = [];
  templateNameMap = {
    profileGroups: ProfileGroupsComponent,
    boolCheck: BoolCheckComponent,
  };
  isMobile = false;

  math = Math;

  ngOnInit() {
    this.columns.forEach((column) => {
      if (column.templateName) {
        this.viewContainerRef.createComponent(
          this.templateNameMap[column.templateName],
        );
      }
    });
    this.isMobile = window.innerWidth < 768;

    this.generateGroupedHeaders();
  }

  ngOnChanges(changes) {
    if (changes.groups) {
      this.generateGroupedHeaders();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  generateGroupedHeaders() {
    this.columnGroups = this.columns.reduce((acc, column) => {
      if (!column.group) {
        acc.push({
          key: '',
          title: '',
          colspan: 1,
        });

        return acc;
      }

      const groupIndex = acc.findIndex((g) => g.key === column.group);
      if (groupIndex === -1) {
        acc.push({
          key: column.group,
          title: this.groups.find((g) => g.key === column.group).title,
          colspan: 1,
        });
      } else {
        acc[groupIndex].colspan += 1;
      }

      return acc;
    }, []);
  }

  onSort(key) {
    const column = this.columns.find((c) => c.key === key);

    if (!column.sortable) {
      return;
    }

    this.columns = this.columns.map((c) => {
      if (c.key === column.key) {
        return c;
      }

      c.sortOrder = undefined;

      return c;
    });

    switch (column.sortOrder) {
      case 'asc':
        column.sortOrder = 'desc';
        break;
      case 'desc':
        column.sortOrder = undefined;
        break;
      default:
        column.sortOrder = 'asc';
    }

    this.sort.emit({ key: column.key, order: column.sortOrder });
  }

  getHighlightColorClass(group) {
    return group && this.groups.find((g) => g.key === group).highlightColor;
  }

  getTableHeight() {
    const tablePadding = 30;
    const footerHeight = this.isMobile ? 0 : 25 + this.bottomMessageHeight;
    return (
      window.innerHeight -
      this.elementRef.nativeElement.offsetTop -
      footerHeight -
      tablePadding
    );
  }
}
