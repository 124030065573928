<sbx-modal-header>{{ data.modalHeader }}</sbx-modal-header>
<sbx-modal-content>
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</sbx-modal-content>
<sbx-modal-footer>
  <ng-container *ngTemplateOutlet="footerTemplate"> </ng-container>
</sbx-modal-footer>

<ng-template #contentTemplate>
  <p>
    Documents generated in Fidelity Private Shares use an electronic version of your
    signature. You will have an opportunity to review each document before it is
    finalized. Fidelity Private Shares will never apply your signature without your
    consent.
  </p>

  <p>The signature chosen below will appear on documents you approve.</p>

  <p class="sbx-signature-text">Which signature style do you prefer?</p>
  <sbx-radio-group
    [items]="[
      {
        labelTemplate: drawTemplate,
        value: 'lowres_drawn'
      },
      {
        labelTemplate: uploadTemplate,
        value: 'upload'
      },
      {
        labelTemplate: typeTemplate,
        value: 'type'
      }
    ]"
    [model]="model"
    (modelChange)="hangleSignatureTypeChange($event)"
    layout="vertical"
  ></sbx-radio-group>

  <ng-template #drawTemplate>
    <div class="sbx-signature-panel">
      <div class="sbx-signature-header">
        <span>Draw Signature</span>
      </div>

      <div class="sbx-signature-body" *ngIf="model === 'lowres_drawn'">
        <div class="sbx-signature-info">
          Use your mouse to create a signature below.
        </div>
        <sbx-signature
          #signatureTemplate
          (captured)="captured($event)"
          (cleared)="cleared()"
        ></sbx-signature>
      </div>
    </div>
  </ng-template>

  <ng-template #uploadTemplate>
    <div class="sbx-signature-panel">
      <div class="sbx-signature-header">
        <span>Upload Signature</span>
      </div>

      <div class="sbx-signature-body" *ngIf="model === 'upload'">
        <div class="sbx-signature-info">
          Upload an image of your signature from your computer.
        </div>
        <div class="sbx-image-input">
          <input
            id="sbx-file-input"
            type="file"
            (change)="fileChangeEvent($event)"
            accept="image/*"
          />
          <label for="sbx-file-input">
            <div class="sbx-image-input-icon">
              <sbx-icon type="upload"></sbx-icon>
            </div>
          </label>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #typeTemplate>
    <div class="sbx-signature-panel">
      <div class="sbx-signature-header">
        <span>Type Signature</span>
      </div>

      <div class="sbx-signature-body" *ngIf="model === 'type'">
        <div class="sbx-signature-info">
          Choose a preferred font for your typed signature. If any edits are needed,
          visit your profile to update your legal name.
        </div>
        <div class="sbx-signature-images">
          <div class="sbx-signature-image" *ngFor="let fontImage of fontImages">
            <sbx-radio
              name="fontImageType"
              [value]="fontImage.value"
              [model]="modelSignatureFont"
              (modelChange)="hangleSignatureFontImageChange($event)"
            ></sbx-radio>
            <img [src]="fontImage.src" />
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #footerTemplate>
  <sbx-button
    class="sbx-button-confirm"
    theme="primary"
    title="Save"
    (click)="modalConfirm()"
    [disabled]="!valid"
  ></sbx-button>
  <sbx-button
    class="sbx-button-back"
    theme="secondary"
    title="Cancel"
    (click)="modalCancel()"
  ></sbx-button>
</ng-template>
