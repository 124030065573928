import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConfigurationService,
  EnvironmentType,
  PaymentOptions,
  PaymentType,
  SendPaymentComponent,
} from '@fmr-ap163331/sps-money-movement-integration-ui';
import { Downgrade } from '@/shared/downgrade';
import { SbxSendPaymentService } from '@/shared/sbx-send-payment/sbx-send-payment.service';
import {
  ISbxSerializeAndSubmitProcessForm,
  SbxSerializeAndSubmitProcessForm,
} from '@/shared/upgraded/serialize-and-submit-process-form.service';
import { SbxZoneService } from '@/core/zone';

interface IPayForSharesForm {
  disclosure_declined: boolean;
  vamm_error: boolean;
  transaction_id: string;
}

const selector = 'sbx-send-payment';

@Downgrade.Component('ngShoobx', selector)
@Component({
  selector: selector,
  templateUrl: './sbx-send-payment.component.html',
  styleUrl: './sbx-send-payment.component.scss',
})
export class SbxSendPaymentComponent implements OnInit {
  @Input()
  public set model(value: IPayForSharesForm) {
    this._model = value;
    if (
      this.sbxSendPaymentService.userConsentDeclined &&
      value.disclosure_declined === false
    ) {
      this.sbxSendPaymentService.userConsentDeclined = false;
      this.sendPaymentComponentRef.resetConsentModal();
    }
    // eslint-disable-next-line camelcase
    this._model.disclosure_declined = this.sbxSendPaymentService.userConsentDeclined;
  }

  @Input()
  public returnUrl: string;

  @Input()
  public clientId: string;

  @Input()
  public paymentOptions: PaymentOptions<PaymentType>;

  @Input()
  public metadata: string;

  @Input()
  public environmentType: EnvironmentType;

  @Output() submitSuccess = new EventEmitter<void>();
  @Output() submitFailure = new EventEmitter<unknown>();
  @Output() declineConsent = new EventEmitter<void>();

  @ViewChild(SendPaymentComponent) sendPaymentComponentRef: SendPaymentComponent;

  private _model: IPayForSharesForm;

  public constructor(
    @Inject(SbxSerializeAndSubmitProcessForm)
    private sbxSerializeAndSubmitProcessForm: ISbxSerializeAndSubmitProcessForm,
    private sbxZoneService: SbxZoneService,
    private sbxSendPaymentService: SbxSendPaymentService,
    private configService: ConfigurationService,
  ) {
    configService.setAppId('AP157080');
  }

  public ngOnInit() {
    this.configService.setEnv(this.environmentType);
    // eslint-disable-next-line camelcase
    this._model.vamm_error = false;
  }

  public handleSuccess(transactionId: string): void {
    // eslint-disable-next-line camelcase
    this._model.transaction_id = transactionId;
    this.sbxSerializeAndSubmitProcessForm('paymentSuccessful_continue');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleFailure(event: unknown): void {
    // eslint-disable-next-line camelcase
    this._model.vamm_error = true;
    this.submitFailure.emit();
  }

  public handleDecline(): void {
    this.sbxSendPaymentService.userConsentDeclined = true;
    // eslint-disable-next-line camelcase
    this._model.disclosure_declined = true;
    this.declineConsent.emit();
  }
}
