<sbx-modal-header>
  <h4 class="modal-title">{{ title }}</h4>
</sbx-modal-header>

<sbx-modal-content>
  <sbx-alert-banner
    *ngIf="globalError"
    [error]="true"
    [customText]="globalError"
  ></sbx-alert-banner>

  <div class="sbx-top-message-container">
    You are adjusting tax amount manually and it is your responsibility to calculate
    taxes. You should consult with a tax professional before doing so. The tax amounts
    are being adjusted for this transaction in US dollars.
  </div>

  <div class="sbx-top-form">
    <sbx-form
      formName="adjustment_reason"
      [form]="form"
      [formFields]="formFields"
      [model]="formData"
    ></sbx-form>
  </div>

  <div class="sbx-joined-form">
    <div class="sbx-joined-form-content">
      <sbx-tax-adjust-event-taxes
        *ngIf="adjustments"
        [(adjustments)]="adjustments"
        (validation)="adjustTaxesFormValid = $event"
      ></sbx-tax-adjust-event-taxes>
    </div>
  </div>
  <div *ngIf="!url.includes('workitems')" class="sbx-bottom-message-container">
    This transaction already occurred. Adjusting tax withholding will only change the
    taxes shown on the Tax Report and will not result in any automatic transfer of
    funds.
    {{ entityName }} is responsible for accurately withholding the applicable taxes.
  </div>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button
    [loading]="loading"
    [disabled]="!adjustments || !form.valid || !adjustTaxesFormValid"
    theme="primary"
    (click)="saveAndClose()"
    title="Save"
  >
  </sbx-button>
  <sbx-button
    theme="secondary"
    [disabled]="loading"
    (click)="sbxModalService.dismiss()"
    title="Cancel"
  ></sbx-button>
</sbx-modal-footer>
