import { ConfigOption } from '@ngx-formly/core';
import { SbxFormFieldComponent } from './wrappers/sbx-form-field.component';
import { SbxStringTextlineComponent } from './fields/sbx-string-textline.component';
import { SbxEmailTextlineComponent } from './fields/sbx-email-textline.component';
import { SbxStringComponent } from './fields/sbx-string.component';
import { SbxBoolRadiosComponent } from './fields/sbx-bool-radios.component';
import { SbxBoolRadiosVerticalComponent } from './fields/sbx-bool-radios-vertical.component';
import { SbxEnumRadiosComponent } from './fields/sbx-enum-radios.component';
import { SbxBoolCheckboxComponent } from './fields/sbx-bool-checkbox.component';
import { SbxChecklistComponent } from './fields/sbx-checklist.component';
import { SbxEnumDropdownComponent } from './fields/sbx-enum-dropdown.component';
import { SbxNumberTextlineComponent } from './fields/sbx-number-textline.component';
import { SbxListComponent } from './fields/sbx-list.component';
import { SbxDateComponent } from './fields/sbx-date.component';
import { SbxStakeholderListComponent } from './fields/sbx-stakeholder-list.component';
import { SbxStakeholderComponent } from './fields/sbx-stakeholder.component';
import { SbxProfileComponent } from './fields/sbx-profile.component';
import { SbxProfileListComponent } from './fields/sbx-profile-list.component';
import { SbxProfileDisplayComponent } from './fields/sbx-profile-display.component';
import { SbxDocumentReferenceFormComponent } from './fields/sbx-document-reference-form.component';
import { SbxPdfUploadFormComponent } from './fields/sbx-pdf-upload-form.component';
import { SbxObjectListComponent } from './fields/sbx-object-list.component';
import { SbxDisplayTemplateComponent } from './fields/sbx-display-template.component';
import { IFile, FileStatus } from '../sbx-dropzone/sbx-dropzone.component';
import { SbxUploadDropzoneComponent } from './fields/sbx-upload-dropzone.component';
import { SbxClipboardComponent } from './fields/sbx-clipboard.component';
import { SbxRichtextComponent } from './fields/sbx-richtext.component';
import { SbxDisplayHtmlComponent } from './fields/sbx-display-html.component';
import { SbxSectionHeaderComponent } from './fields/sbx-section-header.component';
import { SbxLabelComponent } from './fields/sbx-label.component';
import { SbxStockTickerComponent } from './fields/sbx-stock-ticker.component';
import { SbxAddressComponent } from './fields/sbx-address.component';
import { SbxVerificationComponent } from './fields/sbx-verification.component';
import { SbxDocumentListComponent } from './fields/sbx-document-list.component';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';
import { INTERNAL_FORMAT } from '@/shared/sbx-form/fields/sbx-date-base.component.formatters';
import { dateAfterValidator } from '@/shared/sbx-form/fields/validators/date-after.validator';
import { dateBeforeValidator } from '@/shared/sbx-form/fields/validators/date-before.validator';
import { ValidatorError } from '@/shared/sbx-form/fields/validators/validator-error';
import {
  addErrorToFormControl,
  removeErrorFromFormControl,
} from '@/shared/sbx-form/fields/utils/validation.util';

const types = [
  {
    name: 'string-textline',
    component: SbxStringTextlineComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'text',
    component: SbxStringComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'bool-radios',
    component: SbxBoolRadiosComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'bool-radios-vertical',
    component: SbxBoolRadiosVerticalComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'bool-checkbox',
    component: SbxBoolCheckboxComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'checklist',
    component: SbxChecklistComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'enum-radios',
    component: SbxEnumRadiosComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'enum-dropdown',
    component: SbxEnumDropdownComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'list',
    component: SbxListComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'number-textline',
    component: SbxNumberTextlineComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'measurement-textline',
    component: SbxNumberTextlineComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'email-textline',
    component: SbxEmailTextlineComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'date',
    component: SbxDateComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'stakeholder-list',
    component: SbxStakeholderListComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'document-reference',
    component: SbxDocumentReferenceFormComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'profile',
    component: SbxProfileComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'profile-list',
    component: SbxProfileListComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'profile-display',
    component: SbxProfileDisplayComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'pdf-file',
    component: SbxPdfUploadFormComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'objectlist',
    component: SbxObjectListComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'stakeholder',
    component: SbxStakeholderComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'display-template',
    component: SbxDisplayTemplateComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'display-html',
    component: SbxDisplayHtmlComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'section-header',
    component: SbxSectionHeaderComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'dropzone',
    component: SbxUploadDropzoneComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'clipboard',
    component: SbxClipboardComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'richtext',
    component: SbxRichtextComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'stock-ticker',
    component: SbxStockTickerComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'address',
    component: SbxAddressComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'require-verification',
    component: SbxVerificationComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'label',
    component: SbxLabelComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'listOfDocuments',
    component: SbxDocumentListComponent,
    wrappers: ['form-field'],
  },
];

const wrappers = [
  {
    name: 'form-field',
    component: SbxFormFieldComponent,
  },
];

const validationMessages = [
  {
    name: 'required',
    message: 'This field is required.',
  },
  {
    name: 'email',
    message: 'That is not a valid email address.',
  },
  {
    name: 'dropzone',
    message: 'Files are uploading',
  },
  {
    name: 'fieldMatch',
    message: 'That value does not match',
  },
  {
    name: 'selectionRequired',
    message: 'Please type the name of the user and select from the available options.',
  },
  {
    name: 'documentRequired',
    message: 'Please upload or select a document',
  },
  {
    name: 'dateTooEarly',
    message: 'The end date cannot be earlier than the start date.',
  },
  {
    name: 'dateTooLate',
    message: 'The start date cannot be later than the end date.',
  },
  {
    name: 'invalidDate',
    message: 'Invalid date.',
  },
  {
    name: 'emailUnique',
    message: 'This email already exists.',
  },
];

// Regex taken from http://stackoverflow.com/questions/23671733/angularjs-v1-3-x-email-validation-issue
const SB_EMAIL_REGEX =
  /^[_a-z0-9-+]+(\.[_a-z0-9-+]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;

const validators = [
  {
    name: 'email',
    validation: (control) => {
      if (
        !control.value ||
        !control.value.trim() ||
        SB_EMAIL_REGEX.test(control.value)
      ) {
        // Pass
        return null;
      }
      // Fail
      return { email: true };
    },
  },
  {
    name: 'dropzone',
    validation: (control) => {
      if (
        Array.isArray(control.value) &&
        control.value.find((f: IFile) => f.status && f.status !== FileStatus.uploaded)
      ) {
        return { dropzone: true };
      }

      return null;
    },
  },
  {
    name: 'selectionRequired',
    validation: (control) => {
      if (control.value) {
        // Pass
        return null;
      }
      // Fail
      return { selectionRequired: true };
    },
  },
  {
    name: 'documentRequired',
    validation: (control) => {
      if (!control.value.documentTitle) {
        // Fail
        return { documentRequired: true };
      }
      // Pass
      return null;
    },
  },
  {
    name: 'endDate',
    validation: (control): ValidationErrors => {
      const startDateFormControl: AbstractControl = control.parent.get('startDate');
      if (!startDateFormControl.value) {
        return null;
      }
      const startDate: Date = moment(
        startDateFormControl.value,
        INTERNAL_FORMAT,
      ).toDate();
      const validationErrors = dateAfterValidator(startDate)(control);
      if (validationErrors) {
        addErrorToFormControl(startDateFormControl, ValidatorError.DATE_TOO_LATE);
        return validationErrors;
      }
      removeErrorFromFormControl(startDateFormControl, ValidatorError.DATE_TOO_LATE);
      return null;
    },
  },
  {
    name: 'startDate',
    validation: (control): ValidationErrors => {
      const endDateFormControl: AbstractControl = control.parent.get('endDate');
      if (!endDateFormControl.value) {
        return null;
      }
      const endDate: Date = moment(endDateFormControl.value, INTERNAL_FORMAT).toDate();
      const validationErrors = dateBeforeValidator(endDate)(control);
      if (validationErrors) {
        addErrorToFormControl(endDateFormControl, ValidatorError.DATE_TOO_EARLY);
        return validationErrors;
      }
      removeErrorFromFormControl(endDateFormControl, ValidatorError.DATE_TOO_EARLY);
      return null;
    },
  },
];

const extras = {
  checkExpressionOn: 'changeDetectionCheck' as const,
  resetFieldOnHide: false,
};

export const SbxFormConfig: ConfigOption = {
  types,
  wrappers,
  validationMessages,
  validators,
  extras,
};
